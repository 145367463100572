export const useUnbranded = () => {
  const forcedUnbranded = useState<boolean | null | undefined>(
    "forcedUnbranded",
    () => false
  );
  const isCustomDomain = useIsCustomDomain();
  const ubranded = computed({
    get() {
      return (
        useRoute().path.startsWith("/u/") ||
        isCustomDomain.value ||
        forcedUnbranded.value
      );
    },
    set(value) {
      forcedUnbranded.value = value;
    },
  });

  return ubranded;
};
